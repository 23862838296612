import { apiClient, downloadApiClient } from "@/services/HttpService";

// 产品列表
export function fetchProductList(params) {
  return apiClient.get(
    `/products?pageNum=${params.pageNum}&pageSize=${
      params.pageSize
    }&productCode=${params.productCode ? params.productCode : ""}&productName=${
      params.productName ? params.productName : ""
    }&productStatus=${
      params.productStatus ? params.productStatus : ""
    }&productType=${params.productType ? params.productType : ""}&carId=${
      params.carId ? params.carId : ""
    }&functionality=${
      params.functionality ? params.functionality : ""
    }&position=${params.position ? params.position : ""}&factoryId=${
      params.factoryId ? params.factoryId : ""
    }`
  );
}

// 批量修改产品启用状态
export function modifyProductStatus(params) {
  return apiClient.patch(
    `/products?enableFlag=${params.enableFlag}`,
    params.productIds
  );
}
// 添加产品
export function addProduct(form) {
  return apiClient.post("/products", form);
}
// 产品详情
export function fetchProductDetail(productId) {
  return apiClient.get(`/products/${productId}`);
}

// 产品修改
export function modifyProductDetail(form) {
  return apiClient.put("/products", form);
}

// 产品删除
export function deleteProduct(productId) {
  return apiClient.delete(`/products/${productId}`);
}

// 查询采购标准
export function fetchStandard(factoryId) {
  return apiClient.get(`/factories-purchase?factoryId=${factoryId}`);
}

// 运营总部查询采购标准
export function fetchStandardOperation() {
  return apiClient.get(`/factories-purchase`);
}

// 设置采购标准
export function setStandard(params) {
  return apiClient.put("/factories-purchase", params);
}

// 查询bom列表
export function fetchBomList(params) {
  return apiClient.get(
    `/${params.carId}/bom?pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 添加bom
export function addBom(params) {
  return apiClient.post(`/${params.carId}/bom`, params.form);
}

// 修改bom
export function modifyBom(params) {
  return apiClient.put(`/${params.carId}/bom`, params.form);
}

export function updateCarBoms(params) {
  return apiClient.put(`/${params.carId}/boms`, params.bomFormList);
}

// 删除bom
export function deleteBom(carId, accessoryId) {
  return apiClient.delete(`/${carId}/bom/${accessoryId}`);
}

// 根据sku编号查询sku信息
export function fetchSkuInfo(skuCode, factoryId) {
  return apiClient.get(
    `/products/sku?skuCode=${skuCode}&factoryId=${factoryId}`
  );
}

// sku列表中新增sku
export function onAddSku(form) {
  return apiClient.post("/sku", form);
}
// sku列表中修改sku
export function onModifySku(skuId, form) {
  return apiClient.put(`/sku/${skuId}`, form);
}
// sku列表中删除sku
export function onDeleteSku(skuId) {
  return apiClient.delete(`sku/${skuId}`);
}
// sku列表中修改sku状态
export function onModifySkuStatus(skuId, form) {
  return apiClient.patch(`sku/${skuId}`, form);
}
// 获取sku信息
export function fetchSku(skuId) {
  return apiClient.get(`/sku/${skuId}`);
}

// sku条件查询
export function checkCommonSku(factoryId, skuCode) {
  return apiClient.get(`/sku?skuCode=${skuCode}&factoryId=${factoryId}`);
}

// 根据productCode查询是否存在
export function checkProductCodeExists(productCode) {
  return apiClient.get(`/products/exist?productCode=${productCode}`);
}

// bom列表不分页
export function fetchAllBomList(carId) {
  return apiClient.get(`/${carId}/bom/list`);
}

// 商品序列号信息列表
export function fetchProductSerialList(params) {
  return apiClient.get(
    `/product/serial?productCode=${
      params.productCode ? params.productCode : ""
    }&endTime=${params.endTime}&pageNum=${params.pageNum}&pageSize=${
      params.pageSize
    }&startTime=${params.startTime}&productStatus=${
      params.productStatus ? params.productStatus : ""
    }&productName=${
      params.productName ? params.productName : ""
    }&productSerialNo=${
      params.productSerialNo ? params.productSerialNo : ""
    }&productType=${params.productType ? params.productType : ""}`
  );
}

// 运营总部查询商品序列号信息列表
export function fetchOperationsSerialList(params) {
  return apiClient.get(
    `/product/serial/operationsHeadquarters?productCode=${
      params.productCode ? params.productCode : ""
    }&distributorId=${
      params.distributorId ? params.distributorId : ""
    }&pageNum=${params.pageNum}&pageSize=${params.pageSize}&productStatus=${
      params.productStatus !== undefined ? params.productStatus : ""
    }&productName=${
      params.productName ? params.productName : ""
    }&productSerialNo=${
      params.productSerialNo ? params.productSerialNo : ""
    }&productType=${params.productType ? params.productType : ""}`
  );
}

//查询全部的原厂
export function fetchAllFactoriesList() {
  return apiClient.get(
    "/factories?factoryName=&mobile=&pageNum=1&pageSize=999999&userName="
  );
}

// 删除单个商品序列号
export function deleteOnceSkuSerial(goodsId) {
  return apiClient.delete(`/product/serial/${goodsId}`);
}

// 修改单个商品序列号
export function modifySkuSerial(goodsId, productSerialNo) {
  return apiClient.put(
    `/product/serial/${goodsId}?productSerialNo=${productSerialNo}`
  );
}

// 删除多个商品序列号
export function deleteMoreSkuSerial(goodsIdList) {
  return apiClient.delete(`/product/serial/batchDelete`, goodsIdList);
}

// 商品导出
export function exportSerial(goodsIdList) {
  return downloadApiClient.post(`/product/serial/batchExport`, goodsIdList);
}

// 商品序列号全部导出导出
export function exportSerialAll(params) {
  return downloadApiClient.post(`/product/serial/export`, params);
}

// 运营总部商品导出
export function exportHeadquartersSerial(goodsIdList) {
  return downloadApiClient.post(
    `/product/serial/operationsHeadquarters/batchExport`,
    goodsIdList
  );
}

// 运营总部商品序列号全部导出导出
export function exportHeadquartersSerialAll(params) {
  return downloadApiClient.post(
    `/product/serial/operationsHeadquarters/export`,
    params
  );
}

// 查看商品价格信息列表
export function fetchPriceManageList(params) {
  return apiClient.get(
    `/price-manage?skuName=${
      params.skuName ? params.skuName : ""
    }&productType=${params.productType ? params.productType : ""}&pageNum=${
      params.pageNum
    }&pageSize=${params.pageSize}&skuCode=${
      params.skuCode ? params.skuCode : ""
    }&corporationId=${
      params.corporationId ? params.corporationId : ""
    }&effectuateStartDate=${
      params.effectuateStartDate ? params.effectuateStartDate : ""
    }&effectuateEndDate=${
      params.effectuateEndDate ? params.effectuateEndDate : ""
    }&areaCode=${params.areaCode ? params.areaCode : ""}`
  );
}

// 获取商品详细信息
export function fetchPriceDetail(statisticsId) {
  return apiClient.get(`/price-manage/${statisticsId}`);
}

// 商品价格导出
export function exportPrice(param) {
  const queryStr = Object.keys(param).reduce((t, n) => {
    if (!param[n]) return t;
    const str = `${n}=${param[n]}`;
    return t ? `${t}&${str}` : str;
  }, "");
  return downloadApiClient.get(`/price-manage/export?${queryStr}`);
}

// 查询产品树 - 整车
export function fetchProductTreeCar() {
  return apiClient.get(`/productsAndSkuFilter`);
}

// 根据skuId查询productId
export function fetchProductIdBySkuId(skuId) {
  return apiClient.get(`/sku/${skuId}/product-id`);
}

// 查询配件列表
export function fetchPartsList(productType) {
  return apiClient.get(`/products/list?productType=${productType}`);
}

// erp bom同步
export function syncBomFromErp(carId) {
  return apiClient.get(`skus/${carId}/bom/sync`);
}

// 同步整个产品的bom
export function syncProductAllBom(carProductId) {
  return apiClient.get(`/products/${carProductId}/bom/sync`);
}

// 批量删除bom
export function deleteBatchBom(carId, params) {
  return apiClient.delete(`/${carId}/bom`, { data: params });
}

// 批量新增bom
export function addBatchBom(carId, params) {
  return apiClient.post(`/${carId}/bom/batch`, params);
}

// 查询配件和整车列表
export function productsAndSkuName() {
  return apiClient.get(`productsAndSkuName`);
}
