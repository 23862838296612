<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>产品管理</a-breadcrumb-item>
        <a-breadcrumb-item>整车产品列表</a-breadcrumb-item>
        <a-breadcrumb-item v-if="type === 'modify'">
          <a @click="onBackDetail">产品详情</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-if="type === 'add'">
          <a @click="onBackNew">新增产品</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>BOM清单</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <a-row>
        <a-col>
          <div class="info-title">BOM清单</div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="10">商品名称：{{ skuName }}</a-col>
        <a-col :span="10">商品编码：{{ skuCode }}</a-col>
      </a-row>
      <a-row class="btn-item">
        <!--        <a-button type="primary" class="btn-item" @click="onOpenAddModal">-->
        <!--          添加配件-->
        <!--        </a-button>-->
        <a-button
          v-if="type === 'modify'"
          type="link"
          icon="download"
          :href="templateUrl"
        >
          下载导入模板
        </a-button>
        <a-upload
          name="file"
          accept=".xlsx,.xls"
          :multiple="false"
          :action="importDataUrl"
          :headers="headers"
          :show-upload-list="false"
          @change="importProductsChange"
        >
          <a-button
            v-if="type === 'modify'"
            type="white"
            class="button-item"
            :loading="isLoading"
            :disabled="isLoading"
            icon="import"
          >
            批量导入
          </a-button>
        </a-upload>
        <a-button
          type="danger"
          shape="circle"
          icon="exception"
          class="btn-error"
          v-if="isError"
          @click="showError"
        />
        <!--        <a-button v-if="skuId" :loading="syncLoading" @click="onSyncBom">-->
        <!--          从ERP同步-->
        <!--        </a-button>-->
      </a-row>

      <section class="transfer__container">
        <a-transfer
          :data-source="transferData"
          :target-keys="targetKeys"
          :show-search="showSearch"
          :filter-option="
            (inputValue, item) => item.title.indexOf(inputValue) !== -1
          "
          :show-select-all="false"
          @change="onChangeTransfer"
        >
          <template
            slot="children"
            slot-scope="{
              props: {
                direction,
                filteredItems,
                selectedKeys,
                disabled: listDisabled
              },
              on: { itemSelectAll, itemSelect }
            }"
          >
            <a-table
              :row-selection="
                getRowSelection({
                  disabled: listDisabled,
                  selectedKeys,
                  itemSelectAll,
                  itemSelect
                })
              "
              :columns="direction === 'left' ? leftColumns : rightColumns"
              :data-source="filteredItems"
              size="small"
              :style="{ pointerEvents: listDisabled ? 'none' : null }"
              :custom-row="
                ({ key, disabled: itemDisabled }) => ({
                  on: {
                    click: () => {
                      if (itemDisabled || listDisabled) return;
                      itemSelect(key, !selectedKeys.includes(key));
                    }
                  }
                })
              "
              :pagination="false"
              :scroll="{ y: 600 }"
            />
          </template>
        </a-transfer>
      </section>
    </section>

    <div class="examine-container" v-if="type === 'add'">
      <a-button class="button-item" @click="onSave">
        保存
      </a-button>
    </div>

    <!--  添加配件  -->
    <a-modal v-model="addVisible" title="添加配件" @ok="onAddPart">
      <a-form :form="form" class="advanced-search-form">
        <a-form-item label="配件编码">
          <a-select
            v-decorator="[
              'accessoryId',
              { rules: [{ required: true, message: '请输入配件编码' }] }
            ]"
            placeholder="请输入配件编码"
            class="input"
            show-search
            allow-clear
            :filter-option="filterOption"
            @change="handleChange"
            @search="onSearch"
          >
            <a-select-option v-for="item in codeList" :key="item.skuId">
              {{ item.skuCode }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="配件名称">
          <a-input v-model="partName" class="input" disabled />
        </a-form-item>
        <a-form-item label="数量">
          <a-input-number
            v-decorator="[
              'quantity',
              { rules: [{ required: true, message: '请输入数量' }] }
            ]"
            :min="1"
            :precision="0"
            class="input"
            :formatter="limitDecimals"
            :parser="limitDecimals"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!--  修改配件  -->
    <a-modal v-model="modifyVisible" title="修改配件" @ok="onModifyPart">
      <a-form :form="modifyForm" class="advanced-search-form">
        <a-form-item label="配件编码">
          <a-select
            v-decorator="[
              'accessoryId',
              { rules: [{ required: true, message: '请输入配件编码' }] }
            ]"
            placeholder="请输入配件编码"
            class="input"
            show-search
            allow-clear
            disabled
            :filter-option="filterOption"
            @change="handleChange"
            @search="onSearch"
          >
            <a-select-option v-for="item in codeList" :key="item.skuId">
              {{ item.skuCode }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="配件名称">
          <a-input v-model="partName" class="input" disabled />
        </a-form-item>
        <a-form-item label="数量">
          <a-input-number
            v-decorator="[
              'quantity',
              { rules: [{ required: true, message: '请输入数量' }] }
            ]"
            :min="1"
            :precision="0"
            class="input"
            :formatter="limitDecimals"
            :parser="limitDecimals"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!--  错误提示弹窗  -->
    <a-modal v-model="errorVisible" title="提示" :footer="null">
      <a-table
        :columns="errorColumns"
        :data-source="errorData"
        :pagination="false"
        :rowKey="(record, index) => index"
      />
    </a-modal>
  </a-layout-content>
</template>

<script>
import {
  addBatchBom,
  deleteBatchBom,
  fetchAllBomList,
  fetchBomList,
  fetchSku,
  fetchSkuInfo,
  syncBomFromErp
} from "@/services/ProductService";
import { buildHeaders } from "@/components/utils";
import { difference } from "lodash";
import { baseURL } from "@/services/HttpService";

const tblColumns = [
  {
    title: "序号",
    dataIndex: "index"
  },
  {
    title: "配件编码",
    dataIndex: "skuCode"
  },
  {
    title: "配件名称",
    dataIndex: "skuName"
  },
  {
    title: "数量",
    dataIndex: "quantity"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" }
  }
];
const leftTableColumns = [
  {
    dataIndex: "skuCode",
    title: "配件编码"
  },
  {
    dataIndex: "skuName",
    title: "配件名称"
  },
  {
    dataIndex: "quantity",
    title: "数量"
  }
];
const rightTableColumns = [
  {
    dataIndex: "skuCode",
    title: "配件编码"
  },
  {
    dataIndex: "skuName",
    title: "配件名称"
  },
  {
    dataIndex: "quantity",
    title: "数量"
  }
];

const errorColumns = [
  {
    title: "行数",
    dataIndex: "lineNum",
    width: "20%"
  },
  {
    title: "错误信息",
    dataIndex: "errMsg",
    width: "80%"
  }
];

export default {
  data() {
    return {
      tblData: [],
      tblColumns,
      loading: false,
      type: this.$route.params.type,
      skuId: this.$route.params.skuId,
      addVisible: false,
      form: this.$form.createForm(this),
      codeList: [],
      partName: "",
      pagination: {
        hideOnSinglePage: true
      },
      modifyVisible: false,
      modifyForm: this.$form.createForm(this),
      skuCode: "",
      skuName: "",
      partCode: "",
      productId: this.$route.params.productId,
      templateUrl: `${process.env.BASE_URL}BOM导入模板.xlsx`,
      importDataUrl: "",
      headers: buildHeaders(),
      isLoading: false,
      transferData: [],
      targetKeys: [],
      showSearch: true,
      leftColumns: leftTableColumns,
      rightColumns: rightTableColumns,
      isError: false,
      errorData: [],
      errorVisible: false,
      errorColumns,
      syncLoading: false,
      oldTransferData: []
    };
  },
  mounted() {
    this.importDataUrl = `${baseURL}/${this.skuId}/bom/import`;
    fetchSkuInfo("", localStorage.getItem("factoryId")).then(resp => {
      if (resp.data.code === "SUCCESS") {
        const data = resp.data.data;
        this.codeList = data;
        const transferData = data;
        if (transferData && transferData.length > 0) {
          transferData.forEach(item => {
            item.key = item.skuCode;
            item.title = item.skuCode;
            item.description = "";
            item.disabled = false;
            item.quantity = 1;
          });
          this.transferData = transferData;
          this.oldTransferData = [...this.transferData];
        }
        if (this.type === "add") {
          this.onLoadInfo();
          if (localStorage.getItem("tblData") !== null) {
            const tblDataNew = JSON.parse(localStorage.getItem("tblData"));
            if (tblDataNew && tblDataNew.length > 0) {
              tblDataNew.forEach(item => {
                if (item.skuCode === this.skuCode) {
                  if (
                    item.bom &&
                    item.bom.length > 0 &&
                    this.codeList &&
                    this.codeList.length > 0
                  ) {
                    item.bom.forEach(ele => {
                      const obj = this.codeList.find(
                        e => ele.accessoryId === e.skuId
                      );
                      this.targetKeys.push(obj.skuCode);
                    });
                  }
                }
              });
            }
          }
        }
        if (this.type === "modify") {
          this.fetchBomList();
          // this.onFetchList();
          this.onFetchSku();
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "AddBOM") {
      localStorage.removeItem("bomList");
    }
    next();
  },
  methods: {
    // 给新商品添加配件
    addBomToNewSku: function() {
      this.$store.state.isModify = false;
      const list = [];
      if (this.targetKeys && this.targetKeys.length > 0) {
        this.targetKeys.forEach(e => {
          const obj = this.codeList.find(ele => e === ele.skuCode);
          const param = {
            quantity: 1,
            accessoryId: obj.skuId
          };
          list.push(param);
        });
      }
      if (localStorage.getItem("tblData") !== null) {
        const tblData = JSON.parse(localStorage.getItem("tblData"));
        if (tblData && tblData.length > 0) {
          tblData.forEach(item => {
            if (item.skuCode === this.skuCode) {
              item.bom = list;
            }
          });
          localStorage.setItem("tblData", JSON.stringify(tblData));
        }
        this.$message.success("保存成功");
        this.$router.go(-1);
      } else if (
        localStorage.getItem("tblData") !== null &&
        localStorage.getItem("bomList") == null
      ) {
        this.$router.go(-1);
      }
    },
    // 给已有商品添加配件
    addBomToExistingSku: function(direction, moveKeys) {
      this.$store.state.isModify = false;
      if (direction === "left") {
        deleteBatchBom(this.skuId, moveKeys).then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("操作成功");
          }
        });
      } else {
        const list = [];
        if (moveKeys.length > 0) {
          moveKeys.forEach(item => {
            const obj = this.codeList.find(ele => item === ele.skuCode);
            const param = {
              accessoryId: obj.skuId,
              quantity: 1
            };
            list.push(param);
          });
        }
        addBatchBom(this.skuId, list).then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("操作成功");
          }
        });
      }
    },
    // 保存
    onSave() {
      // if (this.type === "add") {
      //
      // } else {
      //   this.addBomToExistingSku();
      // }
      this.addBomToNewSku();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onFetchList() {
      const params = {
        carId: this.skuId,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchBomList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          const records = data.records;
          if (records && records.length > 0) {
            records.forEach((item, index) => {
              item.index = (data.pageNum - 1) * data.pageSize + index + 1;
            });
          }
          this.tblData = records;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
        }
      });
    },
    onOpenAddModal() {
      this.addVisible = true;
      fetchSkuInfo("").then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.codeList = resp.data.data;
          this.partName = "";
        }
      });
    },
    onAddPart() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let isDuplicateAccessory = false;
          if (this.tblData.length > 0) {
            const newPartId = values.accessoryId;
            for (const index in this.tblData) {
              const accessory = this.tblData[index];
              if (accessory.skuId === newPartId) {
                isDuplicateAccessory = true;
                break;
              }
            }
          }

          if (isDuplicateAccessory) {
            this.$message.error("重复添加，所选配件已在BOM表中");
          } else {
            const param = {
              skuId: values.accessoryId,
              skuCode: this.partCode,
              skuName: this.partName,
              quantity: parseInt(values.quantity),
              index: this.tblData.length + 1
            };
            this.tblData.push(param);
            this.$message.success("新增成功");
            this.addVisible = false;
            this.partName = "";
            this.form.resetFields();
            localStorage.setItem("bomList", JSON.stringify(this.targetKeys));
          }
        }
      });
    },
    onSearch(value) {
      fetchSkuInfo(value).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.codeList = resp.data.data;
        }
      });
    },
    handleChange(value) {
      if (this.codeList && this.codeList.length > 0) {
        this.codeList.forEach(item => {
          if (value === item.skuId) {
            this.partName = item.skuName;
            this.partCode = item.skuCode;
          }
        });
      }
      if (typeof value === "undefined") {
        this.partName = "";
        this.form.resetFields();
        this.modifyForm.resetFields();
      }
    },
    onModifyPart() {
      this.modifyForm.validateFields(err => {
        if (!err) {
          if (this.tblData && this.tblData.length > 0) {
            this.tblData.forEach(item => {
              if (
                item.skuId === this.modifyForm.getFieldsValue().accessoryId ||
                item.accessoryId ===
                  this.modifyForm.getFieldsValue().accessoryId
              ) {
                item.quantity = this.modifyForm.getFieldsValue().quantity;
              }
            });
          }
          this.$message.success("修改成功");
          this.modifyVisible = false;
          this.modifyForm.resetFields();
          localStorage.setItem("bomList", JSON.stringify(this.targetKeys));
        }
      });
    },
    onFetchSku() {
      fetchSku(this.skuId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.skuCode = data.skuCode;
          this.skuName = data.skuName;
        }
      });
    },
    onBackNew() {
      this.$router.push({ name: "CompleteVehicleAdd" });
    },
    onLoadInfo() {
      let prop = "";
      let productName = "";
      if (localStorage.getItem("keyPropsMap") !== null) {
        const keyPropsMap = JSON.parse(localStorage.getItem("keyPropsMap"));
        const list = [];
        for (const key in keyPropsMap) {
          list.push(keyPropsMap[key]);
        }
        prop = list.join(" ");
      }
      if (localStorage.getItem("productName") !== null) {
        productName = localStorage.getItem("productName");
      }
      this.skuName = `${productName} ${prop}`;
      if (localStorage.getItem("partCode") !== null) {
        this.skuCode = localStorage.getItem("partCode");
      }
    },
    onBackDetail() {
      this.$router.push({
        name: "CompleteVehicleDetail",
        params: { productId: this.productId }
      });
    },
    limitDecimals(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      }
      return 0;
    },
    importProductsChange(info) {
      this.isLoading = true;
      if (info.file.status === "done") {
        if (info.file.response.code === "SUCCESS") {
          this.errorData = [];
          this.isError = false;
          this.$message.success("导入成功");
          this.fetchBomList();
        } else {
          this.isError = true;
          this.errorData = info.file.response.data || [];
          this.$message.error(info.file.response.errorMsg);
          this.errorVisible = true;
        }
        this.isLoading = false;
      } else if (info.file.status === "error") {
        this.$message.error("导入失败");
        this.isLoading = false;
      }
    },
    onChangeTransfer(nextTargetKeys, direction, moveKeys) {
      console.log(nextTargetKeys);
      console.log(moveKeys);
      if (this.type !== "add") {
        var that = this;
        that.$confirm({
          title: `您确认${
            direction === "left" ? "移除" : "添加"
          }配件的绑定吗？`,
          okText: "确定",
          okType: "info",
          cancelText: "取消",
          onOk() {
            that.targetKeys = nextTargetKeys;
            that.addBomToExistingSku(direction, moveKeys);
          }
        });
      } else {
        this.targetKeys = nextTargetKeys;
      }
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: item => ({
          props: { disabled: disabled || item.disabled }
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys
      };
    },
    fetchBomList() {
      fetchAllBomList(this.skuId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.transferData = [...this.oldTransferData];
          this.targetKeys = [];
          if (data && data.length > 0) {
            data.forEach(item => {
              this.targetKeys.push(item.skuCode);
              if (item.source === "erp") {
                this.transferData.push({
                  key: item.skuCode,
                  title: item.skuCode,
                  description: "",
                  disabled: true,
                  quantity: 1,
                  skuName: item.skuName,
                  skuCode: item.skuCode
                });
              }
            });
          } else {
            this.targetKeys = [];
          }
        }
      });
    },
    // 展示错误
    showError() {
      this.errorVisible = true;
    },
    // 同步bom
    onSyncBom() {
      this.syncLoading = true;
      syncBomFromErp(this.skuId)
        .then(resp => {
          this.syncLoading = false;
          if (resp.data.code === "SUCCESS") {
            this.$message.success("同步成功");
            this.fetchBomList();
          }
        })
        .catch(() => {
          this.syncLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 80px 20px;
  background-color: white;
}
.info-title {
  font-weight: bold;
  margin-bottom: 20px;
}
.btn-item {
  margin: 20px 0;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}
/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 120px !important;
}
.input {
  width: 300px;
}

/deep/ .ant-transfer-customize-list .ant-transfer-list {
  max-width: 50%;
}

.transfer__container {
  margin-top: 20px;
}

.btn-error {
  margin-right: 20px;
}
</style>
