export function buildHeaders() {
  const token = localStorage.getItem("accessToken");
  return {
    Accept: "application/json",
    Authorization: `Bearer ${token}`
  };
}

export function convertToStr(val) {
  if (typeof val === "undefined" || val === null) {
    return "";
  }
  const type = typeof val;
  if (type === "string") {
    return val;
  }
  return `${val}`;
}

// 将true/false
export function convertToChineseBoolean(val) {
  if (val === true) {
    return "是";
  }
  return "否";
}
// 将数字转字符
export function changeNumToString(num) {
  const numList = ["", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  const unit = ["", "十"];
  const str = String(num);
  let result = "";
  if (str.length === 1) {
    result = numList[num];
  } else {
    if (str[0] === "1") {
      const index = Number(str[1]);
      result = unit[1] + numList[index];
    } else {
      const index1 = Number(str[0]);
      const index2 = Number(str[1]);
      result = numList[index1] + unit[1] + numList[index2];
    }
  }
  return result;
}

// 路由默认跳转map，登录页面和前后端页面跳转用
export function buildRoleView() {
  const map = new Map();
  map.set("SuperAdmin", ["21101390100000001731", true]);
  map.set("Admin", ["21101390100000001731", true]);
  map.set("CampManager", ["130dd86a0a254a8c98306233c07c6d08", true]);
  map.set("Comitte", ["130dd86a0a254a8c98306233c07c6d13", true]);
  map.set("CountyComitte", ["130dd86a0a254a8c98306233c07c6d13", true]);
  map.set("Guardian", ["HomePage", false]);
  map.set("Instructor", ["HomePage,130dd86a0a254a8c98306233c07c6d18", false]);
  map.set("Pioneer", ["HomePage", false]);
  return map;
}

// path 例如 StarMedalRules#type:threeStarsMedal 路由名称传参
// 详见功能管理路由名称配置
export function parsePathParam(path) {
  const tmpStr = path.split("#");
  const params = {
    name: tmpStr[0],
    value: tmpStr[1].split(":")[1]
  };
  return params;
}

// 判断角色
export function getRole() {
  const role = localStorage.getItem("role");

  if (role === "Guardian") return true;
  return false;
}

export function formatList(dataList) {
  let str = "";
  let cnt = 0;
  dataList.forEach(data => {
    if (cnt < dataList.length - 1) {
      str = `${str + data},`;
      cnt = cnt + 1;
    } else {
      str = str + data;
    }
  });
  return str;
}

// 数字转汉字
export function toChinesNum(num) {
  const changeNum = [
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九"
  ];
  const unit = ["", "十", "百", "千", "万"];
  // eslint-disable-next-line no-param-reassign
  num = parseInt(num);
  const getWan = temp => {
    const strArr = temp
      .toString()
      .split("")
      .reverse();
    let newNum = "";
    const newArr = [];
    strArr.forEach((item, index) => {
      newArr.unshift(
        item === "0" ? changeNum[item] : changeNum[item] + unit[index]
      );
    });
    const numArr = [];
    newArr.forEach((m, n) => {
      if (m !== "零") numArr.push(n);
    });
    if (newArr.length > 1) {
      newArr.forEach((m, n) => {
        if (newArr[newArr.length - 1] === "零") {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m;
          }
        } else {
          newNum += m;
        }
      });
    } else {
      newNum = newArr[0];
    }

    return newNum;
  };
  const overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) {
    noWan = `0${noWan}`;
  }
  return overWan ? `${getWan(overWan)}万${getWan(noWan)}` : getWan(num);
}
