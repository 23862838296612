var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v("产品管理")]),_c('a-breadcrumb-item',[_vm._v("整车产品列表")]),(_vm.type === 'modify')?_c('a-breadcrumb-item',[_c('a',{on:{"click":_vm.onBackDetail}},[_vm._v("产品详情")])]):_vm._e(),(_vm.type === 'add')?_c('a-breadcrumb-item',[_c('a',{on:{"click":_vm.onBackNew}},[_vm._v("新增产品")])]):_vm._e(),_c('a-breadcrumb-item',[_vm._v("BOM清单")])],1)],1),_c('section',{staticClass:"info-container"},[_c('a-row',[_c('a-col',[_c('div',{staticClass:"info-title"},[_vm._v("BOM清单")])])],1),_c('a-row',[_c('a-col',{attrs:{"span":10}},[_vm._v("商品名称："+_vm._s(_vm.skuName))]),_c('a-col',{attrs:{"span":10}},[_vm._v("商品编码："+_vm._s(_vm.skuCode))])],1),_c('a-row',{staticClass:"btn-item"},[(_vm.type === 'modify')?_c('a-button',{attrs:{"type":"link","icon":"download","href":_vm.templateUrl}},[_vm._v(" 下载导入模板 ")]):_vm._e(),_c('a-upload',{attrs:{"name":"file","accept":".xlsx,.xls","multiple":false,"action":_vm.importDataUrl,"headers":_vm.headers,"show-upload-list":false},on:{"change":_vm.importProductsChange}},[(_vm.type === 'modify')?_c('a-button',{staticClass:"button-item",attrs:{"type":"white","loading":_vm.isLoading,"disabled":_vm.isLoading,"icon":"import"}},[_vm._v(" 批量导入 ")]):_vm._e()],1),(_vm.isError)?_c('a-button',{staticClass:"btn-error",attrs:{"type":"danger","shape":"circle","icon":"exception"},on:{"click":_vm.showError}}):_vm._e()],1),_c('section',{staticClass:"transfer__container"},[_c('a-transfer',{attrs:{"data-source":_vm.transferData,"target-keys":_vm.targetKeys,"show-search":_vm.showSearch,"filter-option":function (inputValue, item) { return item.title.indexOf(inputValue) !== -1; },"show-select-all":false},on:{"change":_vm.onChangeTransfer},scopedSlots:_vm._u([{key:"children",fn:function(ref){
          var ref_props = ref.props;
          var direction = ref_props.direction;
          var filteredItems = ref_props.filteredItems;
          var selectedKeys = ref_props.selectedKeys;
          var listDisabled = ref_props.disabled;
          var ref_on = ref.on;
          var itemSelectAll = ref_on.itemSelectAll;
          var itemSelect = ref_on.itemSelect;
return [_c('a-table',{style:({ pointerEvents: listDisabled ? 'none' : null }),attrs:{"row-selection":_vm.getRowSelection({
                disabled: listDisabled,
                selectedKeys: selectedKeys,
                itemSelectAll: itemSelectAll,
                itemSelect: itemSelect
              }),"columns":direction === 'left' ? _vm.leftColumns : _vm.rightColumns,"data-source":filteredItems,"size":"small","custom-row":function (ref) {
                        var key = ref.key;
                        var itemDisabled = ref.disabled;

                        return ({
                on: {
                  click: function () {
                    if (itemDisabled || listDisabled) { return; }
                    itemSelect(key, !selectedKeys.includes(key));
                  }
                }
              });
          },"pagination":false,"scroll":{ y: 600 }}})]}}])})],1)],1),(_vm.type === 'add')?_c('div',{staticClass:"examine-container"},[_c('a-button',{staticClass:"button-item",on:{"click":_vm.onSave}},[_vm._v(" 保存 ")])],1):_vm._e(),_c('a-modal',{attrs:{"title":"添加配件"},on:{"ok":_vm.onAddPart},model:{value:(_vm.addVisible),callback:function ($$v) {_vm.addVisible=$$v},expression:"addVisible"}},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"配件编码"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'accessoryId',
            { rules: [{ required: true, message: '请输入配件编码' }] }
          ]),expression:"[\n            'accessoryId',\n            { rules: [{ required: true, message: '请输入配件编码' }] }\n          ]"}],staticClass:"input",attrs:{"placeholder":"请输入配件编码","show-search":"","allow-clear":"","filter-option":_vm.filterOption},on:{"change":_vm.handleChange,"search":_vm.onSearch}},_vm._l((_vm.codeList),function(item){return _c('a-select-option',{key:item.skuId},[_vm._v(" "+_vm._s(item.skuCode)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"配件名称"}},[_c('a-input',{staticClass:"input",attrs:{"disabled":""},model:{value:(_vm.partName),callback:function ($$v) {_vm.partName=$$v},expression:"partName"}})],1),_c('a-form-item',{attrs:{"label":"数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'quantity',
            { rules: [{ required: true, message: '请输入数量' }] }
          ]),expression:"[\n            'quantity',\n            { rules: [{ required: true, message: '请输入数量' }] }\n          ]"}],staticClass:"input",attrs:{"min":1,"precision":0,"formatter":_vm.limitDecimals,"parser":_vm.limitDecimals}})],1)],1)],1),_c('a-modal',{attrs:{"title":"修改配件"},on:{"ok":_vm.onModifyPart},model:{value:(_vm.modifyVisible),callback:function ($$v) {_vm.modifyVisible=$$v},expression:"modifyVisible"}},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.modifyForm}},[_c('a-form-item',{attrs:{"label":"配件编码"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'accessoryId',
            { rules: [{ required: true, message: '请输入配件编码' }] }
          ]),expression:"[\n            'accessoryId',\n            { rules: [{ required: true, message: '请输入配件编码' }] }\n          ]"}],staticClass:"input",attrs:{"placeholder":"请输入配件编码","show-search":"","allow-clear":"","disabled":"","filter-option":_vm.filterOption},on:{"change":_vm.handleChange,"search":_vm.onSearch}},_vm._l((_vm.codeList),function(item){return _c('a-select-option',{key:item.skuId},[_vm._v(" "+_vm._s(item.skuCode)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"配件名称"}},[_c('a-input',{staticClass:"input",attrs:{"disabled":""},model:{value:(_vm.partName),callback:function ($$v) {_vm.partName=$$v},expression:"partName"}})],1),_c('a-form-item',{attrs:{"label":"数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'quantity',
            { rules: [{ required: true, message: '请输入数量' }] }
          ]),expression:"[\n            'quantity',\n            { rules: [{ required: true, message: '请输入数量' }] }\n          ]"}],staticClass:"input",attrs:{"min":1,"precision":0,"formatter":_vm.limitDecimals,"parser":_vm.limitDecimals}})],1)],1)],1),_c('a-modal',{attrs:{"title":"提示","footer":null},model:{value:(_vm.errorVisible),callback:function ($$v) {_vm.errorVisible=$$v},expression:"errorVisible"}},[_c('a-table',{attrs:{"columns":_vm.errorColumns,"data-source":_vm.errorData,"pagination":false,"rowKey":function (record, index) { return index; }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }